@import './globals.module.scss';

.trackControlButtons {
    background-color: transparent;
    border: none;
    cursor: pointer;

    & svg {
        width: 24px;
        height: 24px;
        stroke: $secondary;
        transition: 0.2s;
        stroke-linecap: square;
        stroke-linejoin: miter;

        &:hover {
            stroke: $secondaryDark;
            transition: 0.2s;
        }
    }

}

.isPlaying {
    cursor: auto;

    & svg {
        stroke: $disabled;
        opacity: 0.6;

        &:hover {
            stroke: $disabled
        }
    }
}