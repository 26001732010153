@import './globals.module.scss';

.instrumentGrid {
    display: grid;
    grid-template-columns: auto 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 'instrument controls effects';
    padding-bottom: 1rem;
    margin: 0 2rem 2rem 2rem;
    font-size: 0.75rem;
    gap: 0.6rem;
    // border-bottom: 1px solid lightgrey;
}

.instrumentSelectorGrid {
    grid-area: instrument;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & select {
        width: 100%;
    }
}

.instrumentControlsGrid {
    grid-area: controls;
    padding: 1rem;

    & .instrumentControlContainer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 2rem;

        & input[type='number'] {
            width: 40px;
        }
    }


}

.instrumentEffectsGrid {
    grid-area: effects;
    padding: 1rem;

}

.sequencerAccordionLabel {
    // background-color: #fafafa;
    width: 100%;
    border-top: 1px solid darken(#fafafa, 10%);
    border-bottom: 1px solid darken(#fafafa, 10%);
    cursor: pointer;
    font-size: $fSizeNormal;
    padding: 0.3rem 0;

    & .effectsLabel {
        display: flex;
        align-items: center;
    }

    & svg {
        width: 16px;
        height: 16px;
        stroke-width: 1px;
        margin: 0 0.3rem;
    }
}

.odd {
    background-color: $accordionLabel
    // background-color: #fafafa;
}

.even {
    background-color: darken($accordionLabel, 5%);
    // background-color: darken(#fafafa, 2%);
}

.instrumentPartLabel {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 0.3rem 0;
}

.rotatedChevron {
    transform: rotate(180deg);
    transition: 0.15s;
}

.notRotatedChevron {
    transition: 0.15s;
}

.instrumentGridTitle {
    margin: 0;
}

.instrumentGridHr {
    margin: 0 1.6rem;
    border: none;
}

.channelControls>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.partSettings {
    margin-top: 1rem;
    line-height: 2rem;
}

.partSettings>div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & input[type="number"] {
        width: 40px;
    }
}

.addDeleteContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.trashIcon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    opacity: 0.3;
    transition: 0.2s;

    &:hover {
        opacity: 0.8;
        transition: 0.2s;
        border: none;
        background-color: transparent;
        box-shadow: none;
    }

    & svg {
        stroke: $disabled;
        width: 24px;
        height: 24px;
    }
}

.titleAndButtonContainer {
    display: flex;
    align-items: center;
}

.selectPartButton {
    border: 1px solid $border;
    border-radius: 6px;
    background-color: $background;
    font-size: 0.75rem;
    padding: 0.2rem 0.6rem;
    margin-left: 1rem;
    cursor: pointer;
    transition: $transitionTime;

    &:hover {
        background-color: $backgroundDark;
        transition: $transitionTime;
    }
}