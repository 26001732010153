@import './globals.module.scss';

.oscTypes>div {
    display: flex;
    gap: 0.6rem;
    margin-bottom: 1rem;
}

.envelopeParams {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    &>div {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex-basis: 48%;
    }
}

.oscTypeSelected {
    background-color: $secondary !important;

    &:hover {
        background-color: $secondaryDark !important;
    }
}