.notesToUseContainer {
    // column-count: 2;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & div:first-child {
        // flex-grow: 1;

        // text-align: left;
        // background-color: pink;
        margin-top: 1rem;
        width: 100%;
    }

    // text-align: right;
}

.notesToUseCheckboxes {
    // flex-grow: 1;
    // column-count: 2;
    // align-self: start;
    // width: 100%;
    // margin-left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-wrap: wrap;
    // margin-top: 1rem;

    &>div {
        margin-right: 1rem;
    }

    // width: min-content;
}