@import './globals.module.scss';

.notesContainer {
    // width: 600px;
    // height: 600px;
    display: flex;
}

.halfNoteContainer {
    width: 600px;
}

.wholeNoteContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px
}

$scale: 2;
$hNoteWidth: 20px * $scale;
$hNoteMargin: -20px * $scale;
$hNoteTranslateX: -10px * $scale;
$wNoteHeight: 75px * $scale;
$allNoteWidth: 25px * $scale;
$allNoteHeight: 50px * $scale;
$keyShadow: -1px 1px 2px rgba(0, 0, 0, 0.2);


.halfNote {
    background-color: slategrey;
    transform: translateX($hNoteTranslateX);
    width: $hNoteWidth !important;
}

.wholeNote {
    background-color: ghostwhite;
    margin-top: 0px;
    height: $wNoteHeight !important;
    border-bottom: 1px solid $border;
}

#n2,
#n4,
#n7,
#n9,
#n11,
#n14,
#n16,
#n19,
#n21,
#n23 {
    margin-left: $hNoteMargin !important;
}

.allNotes {
    width: $allNoteWidth;
    height: $allNoteHeight;
    border-top: 1px solid $border;
    border-right: 1px solid $border;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: $keyShadow;

    &:first-child {
        border-left: 1px solid $border;
    }

    &:last-child {
        border: none;
        border-top: 1px solid lightgrey;
        border-bottom: 1px solid $border;
        border-right: 1px solid $border;
    }
}

.filled {
    background-color: $accent;
}

.noteList {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    width: 375px;

    & div {
        padding-right: 1rem;
        display: flex;
    }
}

.chordName {
    font-size: 0.75rem;
}

.partDetailsText {
    font-size: 0.75rem;
}

.hidePianoButton {
    text-align: center;
    margin-top: 1rem;

    & button {
        background-color: $background;
        border: 1px solid $border;
        font-size: 0.75rem;
        transition: $transitionTime;
        border-radius: 6px;;
        padding: 0.2rem 0.6rem;
        cursor: pointer;

        &:hover {
            background-color: $backgroundDark;
            transition: $transitionTime;
        }

        &:disabled {
            cursor: default;

            &:hover {
                background-color: $background;
            }
        }
    }
}