@import './globals.module.scss';

.addInstrumentButton {
    background-color: $background;
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 0.5rem 2rem;
    margin-top: 1.3rem;
    margin-bottom: 1.3rem;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;

    & svg {
        stroke: lightgrey;
        width: 18px;
        height: 18px;
        margin-left: .6rem;
    }

    &:hover {
        transition: 0.2s;
        background-color: $backgroundDark;

        & svg {
            transition: 0.2s;
            stroke: $accent
        }
    }
}

.partDetailsContainer {
    margin: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
}