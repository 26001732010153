$sp_normal: 1rem;
$sp_half: $sp_normal / 2;

$fSizeNormal: 0.85rem;
$amount: 15%;

$background: #ffffff;
$backgroundLight: lighten($background, $amount);
$backgroundDark: darken($background, $amount);

$text: #000000;

$primary: #14213d;
$primaryLight: lighten($primary, $amount);
$primaryDark: darken($primary, $amount);

$secondary: #e5e5e5;
$secondaryLight: lighten($secondary, $amount);
$secondaryDark: darken($secondary, $amount);

$accent: #fca311;
$accentLight: lighten($accent, $amount);
$accentDark: darken($accent, $amount);

$disabled: #e63946;

$buttonBorder: lightgrey;
$border: lightgrey;

$accordionLabel: #fafafa;

$transitionTime: 0.2s;

body {
    background-color: $background;
    color: $text;
}

input[type="text"], input[type="number"] {
    padding: 0.3rem 0.4rem;
    border: 1px solid $primary;
    outline: none;
    border-radius: 3px;

    &:focus {
        border: 1px solid darken($disabled, 20%);
    }

}

input[type='range'] {
    -webkit-appearance: none;
    height: 2px;
    border: 1px solid $secondary;
    border-radius: 5px;
    -webkit-transform-origin: top left;
    margin: 1rem 0;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid $secondaryDark;
    background-color: $secondary;
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
    width: 16px;
    height: 16px;
    -webkit-transform: rotate(-90deg);

    &:active {
        background-color: $secondaryDark;
        transform: scale(0.9);
    }
}

input[type="checkbox"] {
    appearance: none;
    background-color: $secondary;
    margin: 0.1rem;
    font: inherit;
    color: currentColor;
    width: 1rem;
    height: 1rem;
    border: 1px solid $secondaryDark;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.15s;
    box-shadow: none;

    &:hover {
        border-color: $secondaryDark;
    }

    &:checked {
        background-color: $primaryLight;
        transition: 0.15s;
    }
}

.simpleButton {
    background-color: $background;
    border: 1px solid $border;
    font-size: 0.75rem;
    transition: $transitionTime;
    border-radius: 6px;
    ;
    padding: 0.2rem 0.6rem;
    cursor: pointer;

    &:hover {
        background-color: $backgroundDark;
        transition: $transitionTime;
    }

    &:disabled {
        cursor: default;

        &:hover {
            background-color: $background;
        }
    }
}