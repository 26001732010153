@import './globals.module.scss';

.sequencerGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
        "steps steps"
        "seqButton seqButton"
        "controls seqEffects";
    padding: 1rem;
    margin: 1rem;
    gap: 0 3rem;
    font-size: 0.75rem;
}

// @media screen and (max-width: 900px) {
//     .sequencerGrid {
//         grid-template-columns: 1fr;
//         grid-template-areas: "steps" "controls";
//     }

//     .sequencerGridStepContainer {
//         overflow-x: auto;
//     }
// }



.sequencerGridStepContainer {
    grid-area: steps;
    text-align: center;
    margin-bottom: 2rem;
}

.sequencerGridControlsContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 0.6rem;
    padding: 0.6rem 1rem;

    &>div {
        width: 50%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        font-size: 0.75rem;
    }
}

.makeDrumsButton {
    grid-area: seqButton;
    margin: 0;
    text-align: center;
    margin-bottom: 2rem;
}

.sequencerEffectsGrid {
    padding: 0.6rem 1rem;
}

.control {
    grid-area: controls;
}

.effects {
    grid-area: seqEffects;

}

.sequencerAccordionLabel {
    cursor: pointer;
    font-size: 0.75rem;
    padding-bottom: 0.3rem;

    & .controlLabel {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        & div {
            padding: 0 0 0.3rem 2rem;
            border-bottom: 1px solid lightgrey;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

    }

    & .effectsLabel {
        display: flex;
        align-items: center;
    }

    & svg {
        width: 16px;
        height: 16px;
        stroke-width: 1px;
        margin: 0 0.3rem;
    }

}

.rotatedChevron {
    transform: rotate(180deg);
    transition: 0.15s;
}

.notRotatedChevron {
    transition: 0.15s;
}