@import-normalize;
@import url(https://fonts.bunny.net/css?family=ibm-plex-sans:300|ibm-plex-sans:300,300i,400,600,600i|work-sans:100);

body {
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div.checkboxContainer {
    /* background-color: #e63946; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
}

div.checkboxContainer > label {
  margin-right: 1rem;
}