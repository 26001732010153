@import './globals.module.scss';

.button {
    background-color: $background;
    border: 1px solid $buttonBorder;
    border-radius: 6px;
    color: $primary;
    padding: 0.5rem 0.8rem;
    cursor: pointer;
    box-shadow: -1px 1px 6px 0px rgba(0, 0, 0, 0.03);
    // margin: 0.1rem;
    transition: 0.15s;

    & .oscTypeSelected {
        background-color: salmon;
    }

    &:hover {
        color: $primaryDark;
        border: 1px solid darken($buttonBorder, 10%);
        background-color: darken($background, 3%);
        transition: 0.15s;
        box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.09);
    }

    &:active {
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    }
}