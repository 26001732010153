@import './globals.module.scss';

.headerContainer {
    background-color: $primary;
    position: sticky;
    top: 0;
    margin: 0;
    padding: $sp_normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0 1px 4px 3px rgba(0, 0, 0, 0.02);
    z-index: 2;

    & h1 {
        padding: 0;
        margin: 0;
        color: $secondaryLight;
    }
}

.headerTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerDataContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0.06;

}

.timeCounter {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
    color: $secondaryLight;
}

.tempoAndSwingContainer {
    & label {
        color: $secondary;
        font-size: 0.75rem;
        margin: 0 0.3rem;
    }

    & input {
        margin: 0 0.3rem;
        background-color: $primary;
        color: $secondary;
        font-size: 1rem;
        border-bottom: 1px solid $secondary;
        width: 3.2rem;

        &:focus {
            border: 1px solid transparent !important;
            border-bottom: 1px solid $disabled !important;
            border-radius: none;
        }

    }
}