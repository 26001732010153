@import './globals.module.scss';

.stepBoxContainer {
    text-align: left;
    // margin-left: 2rem;
    display: inline-block;
    // background-color: cyan;
}

.steps input[type="checkbox"] {
    appearance: none;
    background-color: $secondary;
    margin: 0.1rem;
    font: inherit;
    color: currentColor;
    width: 2rem;
    height: 2rem;
    border: 1px solid $secondaryDark;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.15s;
    box-shadow: none;
    &:nth-child(4n - 3) {
            background-color: $secondaryDark;
        }
    &:hover {
        border-color: $secondaryDark;
    }
    &:checked {
        background-color: $accent;
        transition: 0.15s;
        // box-shadow: -1px 2px 3px 0px rgba(0, 0, 0, 0.08);
        // border: 1px solid darken($disabled, 25%);
    }
}

.steps {
    display: flex;
    align-items: center;
}

.stepTrackLabel {
    margin-left: 1rem;
    text-transform: capitalize;
    font-size: 0.8rem;
}