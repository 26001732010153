.volumeSlider {
    background-color: salmon;
}

.channelControlContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // flex-direction: column;

    & label {
            font-size: 0.75rem;
            margin-right: 1rem;
        }

    & input[type="text"] {
        max-width: 100px;
    }
}

// .channelControlLabel {
//     margin-right: 1rem;
// }

